import React from "react";
import { Link } from 'gatsby';
import * as Icon from 'react-feather';
import { StaticImage } from "gatsby-plugin-image"

const Navbar = () => {
    const [menu, setMenu] = React.useState(true)

    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                            <StaticImage
                                src="../../assets/images/logo.png"
                                alt="Atyeti Logo"
                                loading="eager"
                                placeholder="none"
                            />
                        </Link>

                        <button
                            onClick={toggleNavbar}
                            className={classTwo}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>


                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to="/" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Home
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/about" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        About
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Services <Icon.ChevronDown />
                                    </Link>


                                    <ul className="dropdown-menu">
                                    <li className="nav-item">
                                            <Link to="/cloud-solutions" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Cloud Solutions
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cloud-infrastructure" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Cloud Infrastructure
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/application-modernization" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Application Modernization
                                            </Link>
                                        </li>
                                        
                                        <li className="nav-item">
                                            <Link to="/data-science" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Data Science
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/google-cloud-service" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                            Google Cloud Platform                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/total-quality-management" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Total Quality Management
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to="/enterprise-application" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Enterprise Applications
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/business-process" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Business Process and Operations
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/banking-and-capital" activeClassName="active" onClick={toggleNavbar}
                                                className="nav-link">
                                                Banking and Capital Markets
                                            </Link>
                                        </li>
                                    
                                   
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/life-at-atyeti" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Life at Atyeti
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/careers" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Careers
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;